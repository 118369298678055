import { Theme } from '@/types';

export const themes: Record<string, Theme> = {
  pure: {
    id: 'pure',
    name: 'Pure White',
    type: 'light',
    colors: {
      background: '0 0% 100%',
      foreground: '0 0% 10%',
      card: '0 0% 100%',
      cardForeground: '0 0% 10%',
      popover: '0 0% 100%',
      popoverForeground: '0 0% 10%',
      primary: '220 90% 45%',
      primaryForeground: '0 0% 100%',
      secondary: '0 0% 96%',
      secondaryForeground: '0 0% 10%',
      muted: '0 0% 96%',
      mutedForeground: '0 0% 45%',
      accent: '0 0% 96%',
      accentForeground: '0 0% 10%',
      border: '0 0% 92%',
      input: '0 0% 92%',
      ring: '220 90% 45%'
    }
  },
  warm: {
    id: 'warm',
    name: 'Warm White',
    type: 'light',
    colors: {
      background: '40 30% 98%',
      foreground: '40 10% 10%',
      card: '40 20% 100%',
      cardForeground: '40 10% 10%',
      popover: '40 20% 100%',
      popoverForeground: '40 10% 10%',
      primary: '40 95% 50%',
      primaryForeground: '40 10% 100%',
      secondary: '40 20% 96%',
      secondaryForeground: '40 10% 10%',
      muted: '40 20% 96%',
      mutedForeground: '40 10% 45%',
      accent: '40 20% 96%',
      accentForeground: '40 10% 10%',
      border: '40 20% 92%',
      input: '40 20% 92%',
      ring: '40 95% 50%'
    }
  },
  cool: {
    id: 'cool',
    name: 'Cool White',
    type: 'light',
    colors: {
      background: '210 30% 98%',
      foreground: '210 10% 10%',
      card: '210 20% 100%',
      cardForeground: '210 10% 10%',
      popover: '210 20% 100%',
      popoverForeground: '210 10% 10%',
      primary: '210 95% 50%',
      primaryForeground: '210 10% 100%',
      secondary: '210 20% 96%',
      secondaryForeground: '210 10% 10%',
      muted: '210 20% 96%',
      mutedForeground: '210 10% 45%',
      accent: '210 20% 96%',
      accentForeground: '210 10% 10%',
      border: '210 20% 92%',
      input: '210 20% 92%',
      ring: '210 95% 50%'
    }
  },
  silver: {
    id: 'silver',
    name: 'Silver',
    type: 'medium',
    colors: {
      background: '220 20% 85%',
      foreground: '220 10% 20%',
      card: '220 20% 90%',
      cardForeground: '220 10% 20%',
      popover: '220 20% 90%',
      popoverForeground: '220 10% 20%',
      primary: '220 90% 50%',
      primaryForeground: '220 10% 98%',
      secondary: '220 20% 82%',
      secondaryForeground: '220 10% 20%',
      muted: '220 20% 82%',
      mutedForeground: '220 10% 40%',
      accent: '220 20% 82%',
      accentForeground: '220 10% 20%',
      border: '220 20% 80%',
      input: '220 20% 80%',
      ring: '220 90% 50%'
    }
  },
  slate: {
    id: 'slate',
    name: 'Slate',
    type: 'dark',
    colors: {
      background: '215 25% 20%',
      foreground: '0 0% 98%',
      card: '215 25% 25%',
      cardForeground: '0 0% 98%',
      popover: '215 25% 25%',
      popoverForeground: '0 0% 98%',
      primary: '215 90% 60%',
      primaryForeground: '215 25% 20%',
      secondary: '215 25% 30%',
      secondaryForeground: '0 0% 98%',
      muted: '215 25% 30%',
      mutedForeground: '0 0% 85%',
      accent: '215 25% 30%',
      accentForeground: '0 0% 98%',
      border: '215 25% 35%',
      input: '215 25% 35%',
      ring: '215 90% 60%'
    }
  },
  dawn: {
    id: 'dawn',
    name: 'Dawn',
    type: 'light',
    colors: {
      background: '30 30% 95%',
      foreground: '30 20% 10%',
      card: '30 30% 97%',
      cardForeground: '30 20% 10%',
      popover: '30 30% 97%',
      popoverForeground: '30 20% 10%',
      primary: '30 80% 50%',
      primaryForeground: '30 20% 98%',
      secondary: '30 30% 90%',
      secondaryForeground: '30 20% 10%',
      muted: '30 30% 90%',
      mutedForeground: '30 20% 40%',
      accent: '30 30% 90%',
      accentForeground: '30 20% 10%',
      border: '30 30% 85%',
      input: '30 30% 85%',
      ring: '30 80% 50%',
    },
  },
  
};