import { useState, useEffect } from 'react';
import { Category, ArtItem } from '@/types';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Heart, ShoppingCart, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Dialog } from '@/components/ui/dialog';
import { DialogContent } from '@/components/ui/dialog';
import { DialogHeader } from '@/components/ui/dialog';
import { DialogTitle } from '@/components/ui/dialog';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { MockupPreview } from './MockupPreview';
import { PurchaseOptions } from './PurchaseOptions';
import { ImageProtection } from './ImageProtection';
import { ScrollArea } from '@/components/ui/scroll-area';

interface CategorySectionProps {
  category: Category;
  items: ArtItem[];
  onFavorite: (id: string) => void;
  onAddToCart: (id: string, options: { 
    digital: { included: boolean }; 
    printSizes: { sizeId: string; quantity: number }[] 
  }) => void;
  favorites: string[];
  onSubCategoryClick: (subCategory: string) => void;
  activeSubCategory: string | null;
}

export function CategorySection({ 
  category, 
  items, 
  onFavorite, 
  onAddToCart,
  favorites,
  onSubCategoryClick,
  activeSubCategory
}: CategorySectionProps) {
  const [selectedImage, setSelectedImage] = useState<ArtItem | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const dynamicWatermark = `PREVIEW - ${mousePosition.x % 1000} ${mousePosition.y % 1000}`;

  const mockupUrls = {
    horizontal: 'https://images.unsplash.com/photo-1534349762230-e0cadf78f5da?q=80&w=2670&auto=format&fit=crop',
    vertical: 'https://images.unsplash.com/photo-1513519245088-0e12902e5a38?q=80&w=2574&auto=format&fit=crop',
    wall: 'https://images.unsplash.com/photo-1618219944342-824e40a13285?q=80&w=2670&auto=format&fit=crop',
    frame: 'https://images.unsplash.com/photo-1617713964959-d9a36bbc7b52?q=80&w=2670&auto=format&fit=crop',
    hand: 'https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?q=80&w=2625&auto=format&fit=crop'
  };

  return (
    <section className={cn('p-6 rounded-lg', category.theme.primary)}>
      <h2 className={cn('text-2xl font-bold mb-4', category.theme.accent)}>
        {category.name}
      </h2>
      
      <div className="flex gap-2 mb-4 flex-wrap">
        {category.subCategories.map((sub) => (
          <Badge 
            key={sub} 
            variant={activeSubCategory === sub ? "default" : "secondary"}
            className={cn(
              "cursor-pointer transition-colors",
              activeSubCategory === sub ? "hover:opacity-90" : category.theme.secondary
            )}
            onClick={() => onSubCategoryClick(sub)}
          >
            {sub}
          </Badge>
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {items.filter(item => item.category === category.id).map((item) => (
          <Card key={item.id} className="overflow-hidden">
            <div className="aspect-square relative">
              <HoverCard>
                <HoverCardTrigger asChild>
                  <button 
                    className="w-full h-full"
                    onClick={() => setSelectedImage(item)}
                  >
                    <img 
                      src={item.imageUrl} 
                      alt={item.title}
                      className="object-cover w-full h-full transition-transform hover:scale-105"
                      draggable="false"
                    />
                  </button>
                </HoverCardTrigger>
                <HoverCardContent className="w-[600px] p-4" style={{ maxWidth: '90vw' }}>
                  <ImageProtection>
                    <ScrollArea className="h-[400px]">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2">
                          <MockupPreview
                            imageUrl={item.imageUrl}
                            mockupUrl={mockupUrls.wall}
                            watermark={dynamicWatermark}
                            type="wall"
                          />
                        </div>
                        <MockupPreview
                          imageUrl={item.imageUrl}
                          mockupUrl={mockupUrls.frame}
                          watermark={dynamicWatermark}
                          type="frame"
                        />
                        <MockupPreview
                          imageUrl={item.imageUrl}
                          mockupUrl={mockupUrls.hand}
                          watermark={dynamicWatermark}
                          type="hand"
                        />
                        <div className="col-span-2">
                          <MockupPreview
                            imageUrl={item.imageUrl}
                            mockupUrl={mockupUrls.horizontal}
                            watermark={dynamicWatermark}
                            type="horizontal"
                          />
                        </div>
                        <div className="col-span-2">
                          <MockupPreview
                            imageUrl={item.imageUrl}
                            mockupUrl={mockupUrls.vertical}
                            watermark={dynamicWatermark}
                            type="vertical"
                          />
                        </div>
                      </div>
                    </ScrollArea>
                  </ImageProtection>
                </HoverCardContent>
              </HoverCard>
              <Button
                variant="ghost"
                size="icon"
                className={cn(
                  "absolute top-2 right-2 transition-all duration-200",
                  favorites.includes(item.id)
                    ? "bg-red-500 text-white hover:bg-red-600"
                    : "bg-white/80 hover:bg-white text-gray-600 hover:text-red-500"
                )}
                onClick={() => onFavorite(item.id)}
              >
                <Heart 
                  className={cn(
                    "h-5 w-5 transition-transform duration-200",
                    favorites.includes(item.id) 
                      ? "scale-110 fill-current" 
                      : "scale-100"
                  )} 
                />
              </Button>
            </div>
            <div className="p-4">
              <h3 className="font-semibold text-lg mb-1">{item.title}</h3>
              <p className="text-sm text-muted-foreground mb-2">{item.artist}</p>
              <div className="flex items-center justify-between">
                <span className="font-bold">From ${Math.min(
                  item.purchaseOptions.digital.price,
                  item.purchaseOptions.print.price
                ).toFixed(2)}</span>
                <Button 
                  variant="secondary"
                  size="sm"
                  onClick={() => setSelectedImage(item)}
                  className={cn(category.theme.secondary, 'hover:opacity-90')}
                >
                  <ShoppingCart className="h-4 w-4 mr-2" />
                  Purchase Options
                </Button>
              </div>
            </div>
          </Card>
        ))}
      </div>

      <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
        <DialogContent className="max-w-[90vw] w-[1000px] max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="flex justify-between items-center">
              <span>{selectedImage?.title}</span>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setSelectedImage(null)}
              >
                <X className="h-4 w-4" />
              </Button>
            </DialogTitle>
          </DialogHeader>
          <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-6">
            <ImageProtection>
              <div className="space-y-6 max-w-full">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-2">
                    <MockupPreview
                      imageUrl={selectedImage?.imageUrl || ''}
                      mockupUrl={mockupUrls.wall}
                      watermark={dynamicWatermark}
                      type="wall"
                    />
                  </div>
                  <MockupPreview
                    imageUrl={selectedImage?.imageUrl || ''}
                    mockupUrl={mockupUrls.frame}
                    watermark={dynamicWatermark}
                    type="frame"
                  />
                  <MockupPreview
                    imageUrl={selectedImage?.imageUrl || ''}
                    mockupUrl={mockupUrls.hand}
                    watermark={dynamicWatermark}
                    type="hand"
                  />
                  <div className="col-span-2">
                    <MockupPreview
                      imageUrl={selectedImage?.imageUrl || ''}
                      mockupUrl={mockupUrls.horizontal}
                      watermark={dynamicWatermark}
                      type="horizontal"
                    />
                  </div>
                  <div className="col-span-2">
                    <MockupPreview
                      imageUrl={selectedImage?.imageUrl || ''}
                      mockupUrl={mockupUrls.vertical}
                      watermark={dynamicWatermark}
                      type="vertical"
                    />
                  </div>
                </div>
              </div>
            </ImageProtection>
            <div className="space-y-4">
              <div>
                <h3 className="text-2xl font-semibold">{selectedImage?.title}</h3>
                <p className="text-muted-foreground mt-1">
                  Artist: {selectedImage?.artist}
                </p>
                <p className="mt-4">{selectedImage?.description}</p>
              </div>
              {selectedImage && (
                <PurchaseOptions
                  item={selectedImage}
                  onAddToCart={(options) => {
                    onAddToCart(selectedImage.id, options);
                    setSelectedImage(null);
                  }}
                  className="mt-8"
                />
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );
}