export const categories = [
  {
    id: 'digital-paintings',
    name: 'Digital Paintings',
    theme: {
      primary: 'bg-violet-100 dark:bg-violet-950',
      secondary: 'bg-violet-200 dark:bg-violet-900',
      accent: 'text-violet-600 dark:text-violet-400'
    },
    subCategories: ['Landscapes', 'Portraits', 'Abstract', 'Fantasy']
  },
  {
    id: '3d-art',
    name: '3D Art',
    theme: {
      primary: 'bg-emerald-100 dark:bg-emerald-950',
      secondary: 'bg-emerald-200 dark:bg-emerald-900',
      accent: 'text-emerald-600 dark:text-emerald-400'
    },
    subCategories: ['Characters', 'Environments', 'Abstract', 'Animation']
  },
  {
    id: 'pixel-art',
    name: 'Pixel Art',
    theme: {
      primary: 'bg-amber-100 dark:bg-amber-950',
      secondary: 'bg-amber-200 dark:bg-amber-900',
      accent: 'text-amber-600 dark:text-amber-400'
    },
    subCategories: ['Game Assets', 'Icons', 'Landscapes', 'Characters']
  },
  {
    id: 'generative-art',
    name: 'Generative Art',
    theme: {
      primary: 'bg-rose-100 dark:bg-rose-950',
      secondary: 'bg-rose-200 dark:bg-rose-900',
      accent: 'text-rose-600 dark:text-rose-400'
    },
    subCategories: ['Algorithmic', 'AI Generated', 'Fractals', 'Pattern Art']
  }
];