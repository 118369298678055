// src/components/ui/icons.tsx

import React from 'react';

// You can customize these icons as per your design
export const FulfillmentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
      <path d="M16 8a8 8 0 1 1-16 0 8 8 0 0 1 16 0zM7.293 10.293a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414L8 8.586 6.707 7.293a1 1 0 0 0-1.414 1.414l2 2z"/>
    </svg>
  );
};

