import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useTheme } from '@/hooks/use-theme';
import { 
  Home, 
  Image, 
  Upload, 
  Settings, 
  Users, 
  FolderOpen 
} from 'lucide-react';
import { cn } from '@/lib/utils';

interface SidebarProps {
  open?: boolean;
  className?: string;
}

export function Sidebar({ open = true, className }: SidebarProps) {
  const { theme } = useTheme();

  const menuItems = [
    { icon: Home, label: 'Dashboard', href: '/' },
    { icon: Image, label: 'Gallery', href: '/gallery' },
    { icon: Upload, label: 'Upload', href: '/upload' },
    { icon: FolderOpen, label: 'Collections', href: '/collections' },
    { icon: Users, label: 'Users', href: '/users' },
    { icon: Settings, label: 'Settings', href: '/settings' },
  ];

  return (
    <aside className={cn(
      "fixed left-0 top-16 bottom-0 z-40 w-64 border-r transition-transform duration-300",
      !open && "-translate-x-full",
      theme === 'modern' && "bg-white/80 backdrop-blur-sm dark:bg-slate-900/80",
      theme === 'minimal' && "bg-white dark:bg-slate-950",
      theme === 'colorful' && "bg-white/80 dark:bg-slate-900/80 border-purple-100 dark:border-purple-900",
      className
    )}>
      <ScrollArea className="h-full py-6">
        <nav className="space-y-2 px-4">
          {menuItems.map((item) => (
            <Button
              key={item.href}
              variant="ghost"
              className={cn(
                "w-full justify-start gap-3",
                theme === 'colorful' && "hover:bg-purple-50 dark:hover:bg-purple-900/50"
              )}
            >
              <item.icon className="h-5 w-5" />
              {item.label}
            </Button>
          ))}
        </nav>
      </ScrollArea>
    </aside>
  );
}