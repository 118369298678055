import { supabase } from './supabase';

const BUCKET_NAME = 'printfabrica bucket';

export async function initializeStorage() {
  try {
    const { data: buckets, error: bucketsError } = await supabase.storage.listBuckets();
    
    if (bucketsError) {
      console.error('Storage access error:', bucketsError);
      return false;
    }

    const bucket = buckets?.find(b => b.name === BUCKET_NAME);
    if (!bucket) {
      console.log('Print Fabrica bucket not found');
      return false;
    }

    return true;
  } catch (error) {
    console.error('Storage initialization error:', error);
    return false;
  }
}