import { ReactNode, useEffect } from 'react';

interface ImageProtectionProps {
  children: ReactNode;
}

export function ImageProtection({ children }: ImageProtectionProps) {
  useEffect(() => {
    const preventDefault = (e: Event) => {
      e.preventDefault();
      return false;
    };

    // Prevent right click
    document.addEventListener('contextmenu', preventDefault);
    // Prevent drag start
    document.addEventListener('dragstart', preventDefault);
    // Prevent selection
    document.addEventListener('selectstart', preventDefault);
    // Prevent keyboard shortcuts
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      // Prevent Ctrl/Cmd + S, Ctrl/Cmd + C, Ctrl/Cmd + Shift + I
      if (
        (e.ctrlKey || e.metaKey) && 
        (e.key === 's' || e.key === 'c' || (e.shiftKey && e.key === 'i'))
      ) {
        e.preventDefault();
        return false;
      }
    });

    return () => {
      document.removeEventListener('contextmenu', preventDefault);
      document.removeEventListener('dragstart', preventDefault);
      document.removeEventListener('selectstart', preventDefault);
    };
  }, []);

  return (
    <div 
      className="image-protection"
      style={{
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none'
      }}
    >
      {children}
    </div>
  );
}