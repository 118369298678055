import { ReactNode } from 'react';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Footer } from '../Footer';
import { cn } from '@/lib/utils';
import { useTheme } from '@/hooks/use-theme';

interface MainLayoutProps {
  children: ReactNode;
  sidebarOpen?: boolean;
  className?: string;
}

export function MainLayout({ 
  children, 
  sidebarOpen = true,
  className 
}: MainLayoutProps) {
  const { theme } = useTheme();

  return (
    <div className={cn(
      "min-h-screen",
      theme === 'modern' && "bg-gradient-to-br from-slate-50 to-slate-100 dark:from-slate-900 dark:to-slate-800",
      theme === 'minimal' && "bg-white dark:bg-slate-950",
      theme === 'colorful' && "bg-gradient-to-br from-pink-50 via-blue-50 to-purple-50 dark:from-slate-900 dark:via-purple-900 dark:to-blue-900"
    )}>
      <Header />
      <div className="flex">
        <Sidebar open={sidebarOpen} />
        <main className={cn(
          "flex-1 px-4 py-8 md:px-6 lg:px-8 transition-all duration-300",
          sidebarOpen && "lg:ml-64",
          className
        )}>
          <div className="max-w-7xl mx-auto px-4">
            {children}
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}