import { supabase } from './supabase';
import { useEffect, useState } from 'react';

const ADMIN_EMAIL = import.meta.env.VITE_ADMIN_EMAIL;

export async function isAdmin(): Promise<boolean> {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    return session?.user?.email === ADMIN_EMAIL;
  } catch (error) {
    console.error('Admin check failed:', error);
    return false;
  }
}

interface AdminComponentProps {
  children: React.ReactNode;
}

export function AdminComponent({ children }: AdminComponentProps) {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    isAdmin()
      .then(setIsAuthorized)
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return null;
  if (!isAuthorized) return null;

  return children;
}