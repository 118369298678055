import { sampleArt } from '@/data/sample-art';
import { CategorySection } from './CategorySection';
import { categories } from '@/data/categories';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { X } from 'lucide-react';
import { PurchaseOptions } from '@/types';  // Import the correct type for options

export function Gallery() {
  const [favorites, setFavorites] = useState<string[]>([]);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [activeSubCategory, setActiveSubCategory] = useState<string | null>(null);

  const handleFavorite = (id: string) => {
    setFavorites(prev =>
      prev.includes(id)
        ? prev.filter(fav => fav !== id)
        : [...prev, id]
    );
  };

  const handleAddToCart = (id: string, options: PurchaseOptions) => {  // Use PurchaseOptions type here
    console.log('Added to cart:', { id, options });
  };

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    if (activeCategory === category && activeSubCategory === subCategory) {
      // Clear filters if clicking the active subcategory
      setActiveCategory(null);
      setActiveSubCategory(null);
    } else {
      setActiveCategory(category);
      setActiveSubCategory(subCategory);
    }
  };

  const filteredArt = sampleArt.filter(item => {
    if (!activeCategory && !activeSubCategory) return true;
    return item.category === activeCategory && item.subCategory === activeSubCategory;
  });

  return (
    <div className="space-y-8">
      {activeCategory && activeSubCategory && (
        <div className="flex items-center gap-2">
          <span className="text-sm text-muted-foreground">Filtered by:</span>
          <Badge variant="secondary" className="flex items-center gap-1">
            {activeSubCategory}
            <Button
              variant="ghost"
              size="icon"
              className="h-4 w-4 p-0 hover:bg-transparent"
              onClick={() => {
                setActiveCategory(null);
                setActiveSubCategory(null);
              }}
            >
              <X className="h-3 w-3" />
            </Button>
          </Badge>
        </div>
      )}

      {categories.map(category => (
        <CategorySection
          key={category.id}
          category={category}
          items={filteredArt}
          onFavorite={handleFavorite}
          onAddToCart={handleAddToCart}
          favorites={favorites}
          onSubCategoryClick={(subCategory) => handleSubCategoryClick(category.id, subCategory)}
          activeSubCategory={activeSubCategory}
        />
      ))}
    </div>
  );
}

