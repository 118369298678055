import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { X, Plus, Minus } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { FulfillmentIcon } from '@/components/ui/icons';
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';

interface PurchaseOptionsProps {
  printSizes: { id: string; name: string; dimensions: string; price: number }[];
  isAdmin: boolean;
  onAddToCart: (options: { digital: boolean; printSizes: { sizeId: string; quantity: number }[] }) => void;
}

export function PurchaseOptions({ printSizes, isAdmin, onAddToCart }: PurchaseOptionsProps) {
  const [includeDigital, setIncludeDigital] = useState(true);
  const [selectedPrintSizes, setSelectedPrintSizes] = useState<Map<string, number>>(new Map());

  const handlePrintQuantityChange = (sizeId: string, quantity: number) => {
    setSelectedPrintSizes(prev => {
      const updated = new Map(prev);
      const currentQuantity = updated.get(sizeId) || 0;
      const newQuantity = Math.max(0, currentQuantity + quantity); // Prevent negative quantities
      updated.set(sizeId, newQuantity);
      return updated;
    });
  };

  const removePrintSize = (sizeId: string) => {
    setSelectedPrintSizes(prev => {
      const updated = new Map(prev);
      updated.delete(sizeId);
      return updated;
    });
  };

  const handleAddToCart = () => {
    const printSizeOptions = Array.from(selectedPrintSizes).map(([sizeId, quantity]) => ({
      sizeId,
      quantity,
    }));
    onAddToCart({
      digital: includeDigital,
      printSizes: printSizeOptions,
    });
  };

  return (
    <div>
      {/* Digital Option */}
      <div className="mb-4">
        <label>
          <input
            type="checkbox"
            checked={includeDigital}
            onChange={(e) => setIncludeDigital(e.target.checked)}
            className="mr-2"
          />
          Include Digital Version
        </label>
      </div>

      {/* Print Size Selection */}
      <div className="space-y-4">
        {printSizes.map((size) => {
          const selected = selectedPrintSizes.get(size.id);
          return (
            <div key={size.id} className="flex items-center justify-between p-2 rounded-md border">
              <div className="flex-1">
                <div className="flex items-center gap-2">
                  <p className="font-medium">
                    {size.name} ({size.dimensions})
                  </p>
                  {isAdmin && (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Badge variant="outline" className="ml-2">
                          <FulfillmentIcon className="h-3 w-3" />
                          Fulfillment
                        </Badge>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Details about this fulfillment option.</p>
                      </TooltipContent>
                    </Tooltip>
                  )}
                </div>
                <p className="text-sm text-muted-foreground">${size.price.toFixed(2)}</p>
              </div>
              <div className="flex items-center gap-2">
                {!selected ? (
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-7 w-7"
                    onClick={() => handlePrintQuantityChange(size.id, 1)}
                  >
                    <Plus className="h-3 w-3" />
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outline"
                      size="icon"
                      className="h-7 w-7"
                      onClick={() => handlePrintQuantityChange(size.id, -1)}
                    >
                      <Minus className="h-3 w-3" />
                    </Button>
                    <span className="w-8 text-center">{selected}</span>
                    <Button
                      variant="outline"
                      size="icon"
                      className="h-7 w-7"
                      onClick={() => handlePrintQuantityChange(size.id, 1)}
                    >
                      <Plus className="h-3 w-3" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-7 w-7 text-destructive hover:text-destructive"
                      onClick={() => removePrintSize(size.id)}
                    >
                      <X className="h-3 w-3" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="pt-2 border-t">
        <div className="flex justify-between items-center mb-4">
          <span className="font-medium">Total:</span>
          <span className="text-lg font-bold">
            ${Array.from(selectedPrintSizes.values()).reduce((acc, quantity) => acc + quantity * 10, 0).toFixed(2)}
          </span>
        </div>

        <Button
          className="w-full"
          onClick={handleAddToCart}
          disabled={!includeDigital && selectedPrintSizes.size === 0}
        >
          Add to Cart
        </Button>
      </div>
    </div>
  );
}
