import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { ScrollArea } from '@/components/ui/scroll-area';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from '@/components/ui/table';
import { 
  Upload, 
  X, 
  CheckCircle2, 
  AlertCircle,
  Image as ImageIcon,
  Loader2
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';

interface UploadedImage {
  id: string;
  file: File;
  preview: string;
  status: 'pending' | 'uploading' | 'success' | 'error';
  progress: number;
  error?: string;
}

interface ImageUploadProps {
  onUploadComplete?: (images: { url: string; metadata: any }[]) => void;
  maxFiles?: number;
  maxSize?: number;
  acceptedTypes?: string[];
  className?: string;
}

export function ImageUpload({ 
  onUploadComplete,
  maxFiles = 10,
  maxSize = 50 * 1024 * 1024, // 50MB
  acceptedTypes = ['image/jpeg', 'image/png', 'image/webp'],
  className
}: ImageUploadProps) {
  const [images, setImages] = useState<UploadedImage[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const { toast } = useToast();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newImages = acceptedFiles.map(file => ({
      id: Math.random().toString(36).substr(2, 9),
      file,
      preview: URL.createObjectURL(file),
      status: 'pending' as const,
      progress: 0
    }));

    setImages(prev => [...prev, ...newImages].slice(0, maxFiles));
  }, [maxFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedTypes.reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
    maxSize,
    maxFiles: maxFiles - images.length,
    disabled: isUploading || images.length >= maxFiles
  });

  const uploadImages = async () => {
    setIsUploading(true);
    const uploadedImages: { url: string; metadata: any }[] = [];

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('Please sign in to upload images');
      }

      await Promise.all(
        images
          .filter(img => img.status === 'pending')
          .map(async (image) => {
            try {
              setImages(prev => 
                prev.map(img => 
                  img.id === image.id 
                    ? { ...img, status: 'uploading' }
                    : img
                )
              );

              const fileName = `${Date.now()}-${image.file.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
              const { error: uploadError } = await supabase.storage
                .from('printfabrica bucket')
                .upload(`${session.user.id}/${fileName}`, image.file, {
                  cacheControl: '3600',
                  upsert: false
                });

              if (uploadError) throw uploadError;

              const { data: { publicUrl } } = supabase.storage
                .from('printfabrica bucket')
                .getPublicUrl(`${session.user.id}/${fileName}`);

              uploadedImages.push({
                url: publicUrl,
                metadata: {
                  size: image.file.size,
                  type: image.file.type,
                  name: image.file.name
                }
              });

              setImages(prev => 
                prev.map(img => 
                  img.id === image.id 
                    ? { ...img, status: 'success', progress: 100 }
                    : img
                )
              );
            } catch (error: any) {
              setImages(prev => 
                prev.map(img => 
                  img.id === image.id 
                    ? { 
                        ...img, 
                        status: 'error',
                        error: error.message || 'Upload failed'
                      }
                    : img
                )
              );
            }
          })
      );

      if (uploadedImages.length > 0 && onUploadComplete) {
        onUploadComplete(uploadedImages);
        toast({
          title: "Upload complete",
          description: `Successfully uploaded ${uploadedImages.length} image${uploadedImages.length === 1 ? '' : 's'}`
        });
      }
    } catch (error: any) {
      toast({
        title: "Upload failed",
        description: error.message || "Failed to initialize upload",
        variant: "destructive"
      });
    } finally {
      setIsUploading(false);
    }
  };

  const removeImage = (id: string) => {
    setImages(prev => {
      const image = prev.find(img => img.id === id);
      if (image) {
        URL.revokeObjectURL(image.preview);
      }
      return prev.filter(img => img.id !== id);
    });
  };

  const getStatusIcon = (status: UploadedImage['status']) => {
    switch (status) {
      case 'success':
        return <CheckCircle2 className="h-4 w-4 text-green-500" />;
      case 'error':
        return <AlertCircle className="h-4 w-4 text-red-500" />;
      case 'uploading':
        return <Loader2 className="h-4 w-4 text-blue-500 animate-spin" />;
      default:
        return <ImageIcon className="h-4 w-4 text-gray-500" />;
    }
  };

  return (
    <div className={cn("space-y-4", className)}>
      <div
        {...getRootProps()}
        className={cn(
          "border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors",
          isDragActive 
            ? "border-primary bg-primary/5" 
            : "border-muted-foreground/25 hover:border-primary/50",
          isUploading && "opacity-50 cursor-not-allowed"
        )}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center gap-2">
          <Upload className="h-8 w-8 text-muted-foreground" />
          <div className="text-muted-foreground">
            {isDragActive ? (
              <p>Drop the files here...</p>
            ) : (
              <>
                <p>Drag & drop images here, or click to select</p>
                <p className="text-xs">
                  Supports: {acceptedTypes.join(', ')} (Max {maxFiles} files, {maxSize / (1024 * 1024)}MB each)
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      {images.length > 0 && (
        <>
          <ScrollArea className="h-[300px] border rounded-lg">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[50px]">Preview</TableHead>
                  <TableHead>File</TableHead>
                  <TableHead>Size</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead className="w-[50px]"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {images.map(image => (
                  <TableRow key={image.id}>
                    <TableCell>
                      <img
                        src={image.preview}
                        alt={image.file.name}
                        className="w-10 h-10 object-cover rounded"
                      />
                    </TableCell>
                    <TableCell>
                      <span className="truncate max-w-[200px] block">
                        {image.file.name}
                      </span>
                    </TableCell>
                    <TableCell>
                      {(image.file.size / (1024 * 1024)).toFixed(2)} MB
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        {getStatusIcon(image.status)}
                        {image.status === 'uploading' && (
                          <Progress value={image.progress} className="w-[60px]" />
                        )}
                        {image.status === 'error' && (
                          <span className="text-xs text-red-500">
                            {image.error}
                          </span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => removeImage(image.id)}
                        disabled={image.status === 'uploading'}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>

          <div className="flex justify-between items-center">
            <div className="text-sm text-muted-foreground">
              {images.length} of {maxFiles} files selected
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                onClick={() => setImages([])}
                disabled={isUploading}
              >
                Clear All
              </Button>
              <Button
                onClick={uploadImages}
                disabled={isUploading || !images.some(img => img.status === 'pending')}
              >
                {isUploading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Uploading...
                  </>
                ) : (
                  'Upload All'
                )}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}