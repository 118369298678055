import { cn } from '@/lib/utils';

interface MockupPreviewProps {
  imageUrl: string;
  mockupUrl: string;
  watermark: string;
  type: 'horizontal' | 'vertical' | 'wall' | 'frame' | 'hand';
}

export function MockupPreview({ imageUrl, mockupUrl, watermark, type }: MockupPreviewProps) {
  const insetStyles = {
    horizontal: 'inset-[15%] right-[40%]',
    vertical: 'inset-[20%]',
    wall: 'inset-[25%] top-[30%] bottom-[35%]',
    frame: 'inset-[12%]',
    hand: 'inset-[18%] top-[15%] bottom-[25%]'
  };

  const rotateStyles = {
    horizontal: 'rotate-[5deg]',
    vertical: 'rotate-[-2deg]',
    wall: 'rotate-0 perspective-[1000px] [transform:rotateY(-20deg)]',
    frame: 'rotate-0',
    hand: 'rotate-[2deg]'
  };

  return (
    <div className="relative rounded-lg overflow-hidden">
      <img 
        src={mockupUrl} 
        alt={`${type} mockup`}
        className="w-full h-auto"
        draggable="false"
        loading="lazy"
      />
      <div className={cn("absolute perspective-1000", insetStyles[type])}>
        <div className={cn(
          "relative w-full h-full transform shadow-2xl",
          rotateStyles[type]
        )}>
          <img 
            src={imageUrl}
            alt="Preview"
            className="w-full h-full object-cover"
            style={{ 
              filter: 'brightness(0.95)',
              mixBlendMode: 'multiply'
            }}
            draggable="false"
            loading="lazy"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-2xl font-bold text-white/30 rotate-[-30deg] select-none pointer-events-none whitespace-nowrap">
              {watermark}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}