import { Button } from '@/components/ui/button';
import { useTheme } from '@/hooks/use-theme';
import { Palette } from 'lucide-react';
import { themes } from '@/lib/themes';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

export function ThemeToggle() {
  const { theme, setTheme } = useTheme();

  const themesByType = Object.values(themes).reduce((acc, theme) => {
    if (!acc[theme.type]) {
      acc[theme.type] = [];
    }
    acc[theme.type].push(theme);
    return acc;
  }, {} as Record<string, typeof themes[keyof typeof themes][]>);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <Palette className="h-5 w-5" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Light Themes</DropdownMenuLabel>
        {themesByType.light?.map((t) => (
          <DropdownMenuItem
            key={t.id}
            onClick={() => setTheme(t.id)}
            className={theme.id === t.id ? 'bg-accent' : ''}
          >
            {t.name}
          </DropdownMenuItem>
        ))}
        
        <DropdownMenuSeparator />
        
        <DropdownMenuLabel>Medium Themes</DropdownMenuLabel>
        {themesByType.medium?.map((t) => (
          <DropdownMenuItem
            key={t.id}
            onClick={() => setTheme(t.id)}
            className={theme.id === t.id ? 'bg-accent' : ''}
          >
            {t.name}
          </DropdownMenuItem>
        ))}
        
        <DropdownMenuSeparator />
        
        <DropdownMenuLabel>Dark Themes</DropdownMenuLabel>
        {themesByType.dark?.map((t) => (
          <DropdownMenuItem
            key={t.id}
            onClick={() => setTheme(t.id)}
            className={theme.id === t.id ? 'bg-accent' : ''}
          >
            {t.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}