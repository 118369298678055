import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { themes } from '@/lib/themes';
import { Theme } from '@/types';

interface ThemeState {
  theme: Theme;
  setTheme: (themeId: string) => void;
}

export const useTheme = create<ThemeState>()(
  persist(
    (set) => ({
      theme: themes.dawn,
      setTheme: (themeId) => {
        const newTheme = themes[themeId];
        if (newTheme) {
          set({ theme: newTheme });
          updateThemeVariables(newTheme);
        }
      }
    }),
    {
      name: 'theme-storage',
      onRehydrateStorage: () => (state) => {
        if (state) {
          updateThemeVariables(state.theme);
        }
      }
    }
  )
);

function updateThemeVariables(theme: Theme) {
  const root = document.documentElement;
  Object.entries(theme.colors).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value);
  });
}