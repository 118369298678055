import { MainLayout } from '@/components/layouts/MainLayout';
import { AdminImageUpload } from '@/components/AdminImageUpload';
import { Gallery } from '@/components/Gallery';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { isAdmin } from '@/lib/admin';
import { useState, useEffect } from 'react';

function App() {
  const [showAdmin, setShowAdmin] = useState(false);

  useEffect(() => {
    isAdmin().then(setShowAdmin);
  }, []);

  if (!showAdmin) {
    return (
      <MainLayout>
        <div className="space-y-6">
          <h1 className="text-3xl font-bold">Digital Art Gallery</h1>
          <p className="text-muted-foreground">
            Browse our collection of unique digital artworks
          </p>
          <Gallery />
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="space-y-6">
        <div>
          <h1 className="text-3xl font-bold">Admin Dashboard</h1>
          <p className="text-muted-foreground mt-2">
            Manage your digital art collection
          </p>
        </div>

        <Tabs defaultValue="upload" className="space-y-4">
          <TabsList>
            <TabsTrigger value="upload">Upload Images</TabsTrigger>
            <TabsTrigger value="preview">Preview Gallery</TabsTrigger>
          </TabsList>
          
          <TabsContent value="upload" className="space-y-4">
            <div className="bg-card rounded-lg border p-6">
              <h2 className="text-lg font-semibold mb-4">Upload New Images</h2>
              <AdminImageUpload />
            </div>
          </TabsContent>
          
          <TabsContent value="preview" className="space-y-4">
            <div className="bg-card rounded-lg border p-6">
              <h2 className="text-lg font-semibold mb-4">Gallery Preview</h2>
              <Gallery />
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </MainLayout>
  );
}

export default App;