import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { TooltipProvider } from '@/components/ui/tooltip';
import { Toaster } from '@/components/ui/toaster';
import { initializeStorage } from '@/lib/storage';
import App from './App';
import './index.css';

// Initialize storage but don't block app startup
initializeStorage().catch(error => {
  console.warn('Storage initialization warning:', error);
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <TooltipProvider>
      <App />
      <Toaster />
    </TooltipProvider>
  </StrictMode>
);