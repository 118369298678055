import { PrintSize, FulfillmentDetails } from '@/types';

const fulfillmentOptions: Record<'pod' | 'in-house', FulfillmentDetails> = {
  pod: {
    method: 'pod',
    provider: 'PrintifyAPI',
    estimatedDays: 5,
    shippingOptions: [
      { id: 'standard', name: 'Standard Shipping', price: 4.99, estimatedDays: 5 },
      { id: 'express', name: 'Express Shipping', price: 12.99, estimatedDays: 2 }
    ]
  },
  'in-house': {
    method: 'in-house',
    estimatedDays: 2,
    shippingOptions: [
      { id: 'standard', name: 'Standard Shipping', price: 5.99, estimatedDays: 3 },
      { id: 'express', name: 'Express Shipping', price: 14.99, estimatedDays: 1 }
    ]
  }
};

const printSizes: PrintSize[] = [
  { 
    id: 'small', 
    name: 'Small', 
    dimensions: '8x10"', 
    price: 29.99,
    fulfillment: 'pod',
    processingTime: 2,
    shippingTime: 3
  },
  { 
    id: 'medium', 
    name: 'Medium', 
    dimensions: '16x20"', 
    price: 49.99,
    fulfillment: 'in-house',
    processingTime: 1,
    shippingTime: 2
  },
  { 
    id: 'large', 
    name: 'Large', 
    dimensions: '24x36"', 
    price: 89.99,
    fulfillment: 'pod',
    processingTime: 3,
    shippingTime: 4
  },
  { 
    id: 'xlarge', 
    name: 'Extra Large', 
    dimensions: '36x48"', 
    price: 149.99,
    fulfillment: 'in-house',
    processingTime: 2,
    shippingTime: 3
  },
];

export const sampleArt = [
  // Digital Paintings - Abstract
  {
    id: '1',
    title: 'Ethereal Dreams',
    description: 'A mesmerizing digital painting capturing the essence of dreams.',
    imageUrl: 'https://images.unsplash.com/photo-1579783902614-a3fb3927b6a5',
    category: 'digital-paintings',
    subCategory: 'Abstract',
    artist: 'Elena Rivers',
    purchaseOptions: {
      digital: { type: 'digital', price: 49.99 },
      print: { type: 'print', price: 29.99, sizes: printSizes }
    }
  },
  // Digital Paintings - Landscapes
  {
    id: '2',
    title: 'Mountain Serenity',
    description: 'A breathtaking landscape of mountain peaks at sunset.',
    imageUrl: 'https://images.unsplash.com/photo-1665083082050-0a809e62360f',
    category: 'digital-paintings',
    subCategory: 'Landscapes',
    artist: 'Thomas Peak',
    purchaseOptions: {
      digital: { type: 'digital', price: 59.99 },
      print: { type: 'print', price: 29.99, sizes: printSizes }
    }
  },
  // 3D Art - Characters
  {
    id: '3',
    title: 'Neon Warrior',
    description: 'A stunning 3D character design with cyberpunk aesthetics.',
    imageUrl: 'https://images.unsplash.com/photo-1633077330226-0e2d49c85bc7',
    category: '3d-art',
    subCategory: 'Characters',
    artist: 'Maya Chen',
    purchaseOptions: {
      digital: { type: 'digital', price: 79.99 },
      print: { type: 'print', price: 39.99, sizes: printSizes }
    }
  },
  // 3D Art - Environments
  {
    id: '4',
    title: 'Crystal Cave',
    description: 'An otherworldly 3D environment with glowing crystals.',
    imageUrl: 'https://images.unsplash.com/photo-1633496099823-7e414f7d9f85',
    category: '3d-art',
    subCategory: 'Environments',
    artist: 'Alex Stone',
    purchaseOptions: {
      digital: { type: 'digital', price: 69.99 },
      print: { type: 'print', price: 34.99, sizes: printSizes }
    }
  },
  // Pixel Art - Game Assets
  {
    id: '5',
    title: 'Retro Adventure',
    description: 'A charming pixel art game scene with vibrant colors.',
    imageUrl: 'https://images.unsplash.com/photo-1550745165-9bc0b252726f',
    category: 'pixel-art',
    subCategory: 'Game Assets',
    artist: 'Pixel Pete',
    purchaseOptions: {
      digital: { type: 'digital', price: 29.99 },
      print: { type: 'print', price: 24.99, sizes: printSizes }
    }
  },
  // Pixel Art - Characters
  {
    id: '6',
    title: 'Forest Spirit',
    description: 'A mystical pixel art character design.',
    imageUrl: 'https://images.unsplash.com/photo-1550745165-3bc1e6851c91',
    category: 'pixel-art',
    subCategory: 'Characters',
    artist: 'Bit Master',
    purchaseOptions: {
      digital: { type: 'digital', price: 24.99 },
      print: { type: 'print', price: 19.99, sizes: printSizes }
    }
  },
  // Generative Art - AI Generated
  {
    id: '7',
    title: 'Neural Symphony',
    description: 'An AI-generated masterpiece of swirling colors and patterns.',
    imageUrl: 'https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead',
    category: 'generative-art',
    subCategory: 'AI Generated',
    artist: 'AI Artisan',
    purchaseOptions: {
      digital: { type: 'digital', price: 89.99 },
      print: { type: 'print', price: 44.99, sizes: printSizes }
    }
  },
  // Generative Art - Fractals
  {
    id: '8',
    title: 'Fractal Universe',
    description: 'A mesmerizing fractal pattern suggesting infinite depth.',
    imageUrl: 'https://images.unsplash.com/photo-1618005198784-bbdc9d1aa2fb',
    category: 'generative-art',
    subCategory: 'Fractals',
    artist: 'Pattern Pro',
    purchaseOptions: {
      digital: { type: 'digital', price: 69.99 },
      print: { type: 'print', price: 39.99, sizes: printSizes }
    }
  },
  // Digital Paintings - Portraits
  {
    id: '9',
    title: 'Mystic Portrait',
    description: 'A haunting digital portrait with ethereal elements.',
    imageUrl: 'https://images.unsplash.com/photo-1578632767115-351597cf2477',
    category: 'digital-paintings',
    subCategory: 'Portraits',
    artist: 'Sarah Dreams',
    purchaseOptions: {
      digital: { type: 'digital', price: 54.99 },
      print: { type: 'print', price: 29.99, sizes: printSizes }
    }
  },
  // Digital Paintings - Fantasy
  {
    id: '10',
    title: 'Dragon\'s Lair',
    description: 'A detailed fantasy scene featuring a mythical dragon.',
    imageUrl: 'https://images.unsplash.com/photo-1635322966219-b75ed372eb01',
    category: 'digital-paintings',
    subCategory: 'Fantasy',
    artist: 'Dragon Master',
    purchaseOptions: {
      digital: { type: 'digital', price: 79.99 },
      print: { type: 'print', price: 49.99, sizes: printSizes }
    }
  },
  // 3D Art - Abstract
  {
    id: '11',
    title: 'Geometric Dreams',
    description: 'Abstract 3D shapes creating a mesmerizing composition.',
    imageUrl: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e',
    category: '3d-art',
    subCategory: 'Abstract',
    artist: 'Shape Master',
    purchaseOptions: {
      digital: { type: 'digital', price: 64.99 },
      print: { type: 'print', price: 34.99, sizes: printSizes }
    }
  },
  // 3D Art - Animation
  {
    id: '12',
    title: 'Dancing Light',
    description: 'A stunning 3D animation frame capturing light in motion.',
    imageUrl: 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe',
    category: '3d-art',
    subCategory: 'Animation',
    artist: 'Motion Arts',
    purchaseOptions: {
      digital: { type: 'digital', price: 84.99 },
      print: { type: 'print', price: 44.99, sizes: printSizes }
    }
  },
  // Pixel Art - Landscapes
  {
    id: '13',
    title: 'Sunset Pixels',
    description: 'A serene pixel art landscape at golden hour.',
    imageUrl: 'https://images.unsplash.com/photo-1633496099618-8c37d019acf5',
    category: 'pixel-art',
    subCategory: 'Landscapes',
    artist: 'Pixel Master',
    purchaseOptions: {
      digital: { type: 'digital', price: 34.99 },
      print: { type: 'print', price: 24.99, sizes: printSizes }
    }
  },
  // Pixel Art - Icons
  {
    id: '14',
    title: 'Retro Icons Set',
    description: 'A collection of nostalgic pixel art icons.',
    imageUrl: 'https://images.unsplash.com/photo-1633497387955-6b4f12099257',
    category: 'pixel-art',
    subCategory: 'Icons',
    artist: 'Icon Crafter',
    purchaseOptions: {
      digital: { type: 'digital', price: 19.99 },
      print: { type: 'print', price: 14.99, sizes: printSizes }
    }
  },
  // Generative Art - Pattern Art
  {
    id: '15',
    title: 'Infinite Patterns',
    description: 'Computer-generated patterns creating mesmerizing textures.',
    imageUrl: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e',
    category: 'generative-art',
    subCategory: 'Pattern Art',
    artist: 'Pattern Master',
    purchaseOptions: {
      digital: { type: 'digital', price: 74.99 },
      print: { type: 'print', price: 39.99, sizes: printSizes }
    }
  },
  // Generative Art - Algorithmic
  {
    id: '16',
    title: 'Code Poetry',
    description: 'Abstract patterns generated through mathematical algorithms.',
    imageUrl: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e',
    category: 'generative-art',
    subCategory: 'Algorithmic',
    artist: 'Code Artist',
    purchaseOptions: {
      digital: { type: 'digital', price: 94.99 },
      print: { type: 'print', price: 49.99, sizes: printSizes }
    }
  }
];

export { fulfillmentOptions };