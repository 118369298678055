import { AdminComponent } from '@/lib/admin';
import { ImageUpload } from './ImageUpload';
import type { ComponentProps } from 'react';

type AdminImageUploadProps = Omit<ComponentProps<typeof ImageUpload>, 'onUploadComplete'>;

export function AdminImageUpload(props: AdminImageUploadProps) {
  return (
    <AdminComponent>
      <ImageUpload
        {...props}
        onUploadComplete={(images) => {
          console.log('Uploaded images:', images);
        }}
      />
    </AdminComponent>
  );
}