import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ThemeToggle } from '@/components/ThemeToggle';
import { useTheme } from '@/hooks/use-theme';
import { Menu, Search, Bell } from 'lucide-react';
import { cn } from '@/lib/utils';

export function Header() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { theme } = useTheme();

  return (
    <header className={cn(
      "sticky top-0 z-50 w-full border-b transition-all duration-300",
      theme === 'modern' && "bg-white/80 backdrop-blur-sm dark:bg-slate-900/80",
      theme === 'minimal' && "bg-white dark:bg-slate-950",
      theme === 'colorful' && "bg-white/80 dark:bg-slate-900/80 border-purple-100 dark:border-purple-900"
    )}>
      <div className="container mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center gap-4">
            <Button variant="ghost" size="icon">
              <Menu className="h-5 w-5" />
            </Button>
            <h1 className="text-xl font-semibold">Your Brand</h1>
          </div>

          <div className={cn(
            "fixed inset-0 lg:static lg:flex-1 lg:px-8 transition-all duration-300",
            !isSearchOpen && "hidden lg:block"
          )}>
            <div className="relative lg:max-w-md lg:mx-auto">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
              <Input 
                placeholder="Search..." 
                className="w-full pl-9 bg-muted/50"
              />
            </div>
          </div>

          <div className="flex items-center gap-2">
            <Button 
              variant="ghost" 
              size="icon" 
              className="lg:hidden"
              onClick={() => setIsSearchOpen(!isSearchOpen)}
            >
              <Search className="h-5 w-5" />
            </Button>
            <Button variant="ghost" size="icon">
              <Bell className="h-5 w-5" />
            </Button>
            <ThemeToggle />
          </div>
        </div>
      </div>
    </header>
  );
}